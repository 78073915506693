<template>
  <div class="env-component-settings pt-6">
    <section
      aria-label="Component info"
      class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5 mb-2">
          {{ $t('environment.component.info') }}
        </h2>
        <p>
          {{ $t('componentInfo.sectionDescription') }}
        </p>
      </header>
      <div class="two-column-section__content">
        <v-card
          outlined
          class="pa-4 space-y-2">
          <v-text-field
            v-model.trim="$v.formData.name.$model"
            class="required-field"
            :label="$t('forms.fieldName')"
            :error-messages="nameErrors"
            required
            @blur="$v.formData.name.$touch()"/>
          <v-text-field
            class="required-field"
            :value="componentCanonical"
            :label="$t('componentInfo.canonical')"
            :error-messages="canonicalErrors"
            disabled
            readonly
            required>
            <template #append>
              <CyCopyButton
                :copy-value="componentCanonical"/>
            </template>
          </v-text-field>
          <v-textarea
            v-model="$v.formData.description.$model"
            class="mt-2 mb-4"
            :value="_.get(component, 'description')"
            :label="$t('forms.fieldDescription')"
            :hint="$t('componentInfo.descriptionHint')"
            rows="3"
            persistent-hint
            auto-grow/>
          <CyButton
            type="submit"
            variant="primary"
            icon="done"
            :loading="saving"
            :disabled="!canSave"
            @click.native="updateComponent">
            {{ $t('forms.btnSave') }}
          </CyButton>
        </v-card>

        <p
          class="stack-summary__label"
          v-text="$t('untranslated.stack')"/>

        <CyWizardServiceCard
          hide-more-btn
          class="clickable"
          :service="_.get(component, 'service_catalog')"
          @click.native="showStackPreview = true"/>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('configuration') }}
        </h2>
      </header>

      <div class="two-column-section__content">
        <v-card
          outlined
          @click="$router.push({ name: 'envComponentConfiguration', params: { envCanonical, componentCanonical } })">
          <v-card-text class="card-wrapper">
            <CyAvatar
              class="card-wrapper__avatar"
              :item="{
                icon: 'data_object',
                color: 'dev',
              }"/>
            <div class="card-wrapper__text">
              <div>
                <h3 class="content__title">
                  {{ $t('config.title') }}
                </h3>
                <div
                  class="content__subtitle"
                  v-html="$sanitizeHtml($t('config.description'))"/>
              </div>
              <CyButton
                class="content__button"
                icon="chevron_right"
                icon-only
                variant="tertiary"
                theme="primary"
                sm/>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('scheduling.sectionTitle') }}
        </h2>
      </header>

      <div class="two-column-section__content">
        <v-card
          outlined
          @click="$toggle.showScheduleModal">
          <v-card-text class="card-wrapper">
            <CyAvatar
              class="card-wrapper__avatar"
              :item="{
                icon: 'event',
                color: 'dev',
              }"/>

            <div class="card-wrapper__text">
              <div>
                <h3 class="content__title">
                  {{ $t('scheduling.title') }}
                </h3>
                <div
                  class="content__subtitle"
                  v-html="$sanitizeHtml($t('scheduling.description'))"/>
              </div>
              <CyButton
                class="content__button"
                icon="chevron_right"
                icon-only
                variant="tertiary"
                theme="primary"
                sm/>
            </div>
          </v-card-text>
          <v-divider v-if="!_.isEmpty(startStopPipeline)"/>
          <v-card-text
            v-if="!_.isEmpty(startStopPipeline)"
            v-ripple.prevent.stop="false"
            :ripple="false"
            class="content"
            @click.prevent.stop="">
            <div class="content__button">
              <CyButton
                v-has-rights-to="['CreateBuild', projectCanonical]"
                aria-label="Start"
                icon="play_arrow"
                variant="secondary"
                theme="primary"
                sm
                @click.prevent.stop="startStopEnvironment('start')">
                {{ $t('pipeline.start') }}
              </CyButton>
              <CyButton
                v-has-rights-to="['CreateBuild', projectCanonical]"
                aria-label="Stop"
                icon="stop"
                variant="secondary"
                theme="primary"
                sm
                @click.prevent.stop="startStopEnvironment('stop')">
                {{ $t('pipeline.stop') }}
              </CyButton>
            </div>
            <span class="content__link">
              <router-link
                role="link"
                class="cy-link"
                :to="{
                  name: 'envComponentPipeline',
                  params: { pipelineCanonical: startStopPipeline.name, backRouteTo: $route.name },
                }"
                @click.native.stop="">
                {{ $t('pipeline.viewStartStop') }}
              </router-link>
            </span>
          </v-card-text>
        </v-card>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('externalBackends.iacStateManagement') }}
        </h2>
        <p class="subtitle">
          <i18n
            path="externalBackends.iacDescription"
            tag="p">
            <template #learnMore>
              <a
                :href="$docLinks.terraform.state"
                target="_blank"
                rel="noopener noreferrer"
                class="cy-link">{{ $t('learnMore') }}</a>
            </template>
          </i18n>
        </p>
      </header>

      <div class="two-column-section__content">
        <v-card
          outlined
          :to="terraformConfigRoute">
          <v-card-text class="card-wrapper">
            <CyIconCredential
              class="card-wrapper__avatar"
              size="32"
              type="terraform"/>
            <div class="card-wrapper__text">
              <div>
                <h3 class="content__title">
                  {{ $t('externalBackends.RemoteTfBackend') }}
                </h3>
                <div
                  class="content__subtitle"
                  v-html="$sanitizeHtml($t('externalBackends.remoteTfBackendHint'))"/>
              </div>
              <div class="content__button">
                <div
                  v-if="loading.externalBackends"
                  class="sk-block sk-dark sk-h-5 sk-w-17 mr-3"/>
                <CyTag
                  v-else
                  class="mr-3"
                  element-type="div"
                  small
                  :variant="externalBackendId ? 'secondary' : 'default'">
                  {{ $t(externalBackendId ? 'enabled' : 'disabled') }}
                </CyTag>
                <CyButton
                  icon="chevron_right"
                  icon-only
                  variant="tertiary"
                  theme="primary"
                  sm/>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="pb-6">
      <CyDangerZone
        v-has-rights-to="'UpdateProject'"
        class="two-column-section"
        :entity-name="_.get(component, 'name')"
        :entity-type="_.toLower($t('Component'))"
        :delete-button-text="`${$t('environment.component.delete')}...`"
        :delete-title="$t('environment.component.delete')"
        :delete-text="$t('delete.description')"
        @delete="deleteComponent">
        <template #confirmText>
          <p class="font-weight-bold">
            {{ $t('environment.component.deleteInfo') }}
          </p>
          <div class="d-flex align-center">
            <p class="ma-0 mr-2">
              {{ $t('environment.component.deleteConfirm') }}
            </p>
            <span class="d-flex align-center font-weight-bold">
              <CyStackAvatar
                :stack="component.service_catalog"
                size="24px"
                class="mr-1"
                sm/>
              {{ _.get(component, 'name') }}
            </span>
          </div>
        </template>
      </CyDangerZone>
    </section>

    <CyFormsStartStop
      v-if="showScheduleModal"
      :edit="!_.isEmpty(startStopPipeline)"
      :component-canonical="componentCanonical"
      :environment="environment"
      :project-canonical="projectCanonical"
      @updated="GET_PROJECT_PIPELINES"
      @close="$toggle.showScheduleModal(false)"/>

    <portal
      v-if="showStackPreview"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: () => { showStackPreview = false },
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :show-select-btn="false"
            :stack="component.service_catalog"
            :stack-belongs-to-current-org="isStackInCurrentOrg(previewedStack)"
            @close="showStackPreview = false"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CyCopyButton from '@/components/CyCopyButton.vue'
import CyDangerZone from '@/components/CyDangerZone.vue'
import CyFormsStartStop from '@/components/CyFormsStartStop.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import CyStackPreview from '@/components/CyStackPreview.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageEnvComponentSettings',
  components: {
    CyCopyButton,
    CyDangerZone,
    CyFormsStartStop,
    CyStackAvatar,
    CyStackPreview,
    CyWizardServiceCard,
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
    envCanonical: {
      type: String,
      required: true,
    },
    componentCanonical: {
      type: String,
      required: true,
    },
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical, component, componentCanonical } = this

    return constructBreadcrumb(this.$options.name, this.$t('Settings'), [
      {
        label: component?.name || componentCanonical,
        name: 'envComponentOverview',
        params: { projectCanonical, envCanonical, componentCanonical },
      },
      {
        label: this.$t('Components'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: envCanonical,
        name: 'environment',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const name = this.component?.name || this.componentCanonical
    return {
      title: this.$t('componentSettings', { name }),
      avatar: {
        type: 'stack',
        item: this.component?.service_catalog || {},
      },
    }
  },
  data: () => ({
    saving: false,
    formData: {
      name: '',
      description: '',
    },
    loading: {
      start: false,
      stop: false,
      externalBackends: false,
    },
    externalBackendId: null,
    showScheduleModal: false,
    showStackPreview: false,
  }),
  validations () {
    return {
      formData: {
        name: { required },
        description: {},
      },
    }
  },
  computed: {
    ...mapGetters('organization/project', [
      'envComponent',
      'envs',
      'infraViewExternalBackends',
    ]),
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    component () {
      return this.envComponent(this.envCanonical, this.componentCanonical)
    },
    environment () {
      return this.envs.find(({ canonical }) => canonical === this.envCanonical)
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canonicalErrors () {
      const errors = []
      const { $dirty } = this.$v.formData.name
      if (!$dirty) return errors
      return errors
    },
    canSave () {
      return !this.$v.$invalid && this.$hasDataChanged('formData')
    },
    startStopPipeline () {
      const pipelines = extractStartStopPipelines(this.pipelines)?.startStop
      const pipeline = _.find(pipelines, ({ project, environment, component }) => {
        return project.canonical === this.projectCanonical &&
          environment.canonical === this.envCanonical &&
          component.canonical === this.componentCanonical
      })
      return pipeline
    },
    terraformConfigRoute () {
      const { externalBackendId, envCanonical, componentCanonical } = this
      const params = _.pickBy({ componentCanonical, envCanonical, externalBackendId, backRouteTo: this.$route.name })
      return externalBackendId
        ? { name: 'envComponentConfigInfraView', params }
        : { name: 'newEnvComponentConfigInfraView', params }
    },
  },
  watch: {
    component: {
      handler (component) {
        if (!component) return
        this.setup()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'UPDATE_ENV_COMPONENT',
      'GET_PROJECT_PIPELINES',
      'GET_EXTERNAL_BACKENDS',
      'DELETE_ENV_COMPONENT',
      'GET_PROJECT_ENVS',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async updateComponent () {
      this.saving = true
      const { name, description } = this.formData
      const updatedComponent = {
        ...this.component,
        name,
        description,
      }
      await this.UPDATE_ENV_COMPONENT({
        envCanonical: this.envCanonical,
        component: updatedComponent,
        displaySuccessMessage: true,
        refreshProject: true,
        refreshConfig: true,
      })
      this.$setOriginalData('formData')
      this.saving = false
    },
    async setup () {
      this.formData.name = this.component?.name
      this.formData.description = this.component.description
      this.$toggle.loading.externalBackends(true)
      await Promise.all([
        this.GET_PROJECT_PIPELINES(),
        this.GET_EXTERNAL_BACKENDS(),
      ])
      this.$toggle.loading.externalBackends(false)
      this.externalBackendId = _.get(_.find(this.infraViewExternalBackends, {
        project_canonical: this.projectCanonical,
        environment_canonical: this.envCanonical,
        component_canonical: this.componentCanonical,
      }), 'id')
      this.$setOriginalData('formData')
    },
    async startStopEnvironment (type) {
      const { projectCanonical, envCanonical, orgCanonical, componentCanonical } = this
      const pipelineCanonical = this.startStopPipeline?.name
      this.loading[type] = true
      const { data } = await this.$cycloid.ydAPI.createBuild(orgCanonical, projectCanonical, envCanonical, componentCanonical, pipelineCanonical, type) || {}
      if (data) {
        const successMessage = type === 'start' ? 'started' : 'stopped'
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.environment.${successMessage}`) })
      }
      this.loading[type] = false
    },
    async deleteComponent () {
      await this.DELETE_ENV_COMPONENT({
        envCanonical: this.envCanonical,
        componentCanonical: this.componentCanonical,
      })
      this.GET_PROJECT_ENVS()
      this.$router.push({ name: 'environmentOverview', params: { projectCanonical: this.projectCanonical, envCanonical: this.envCanonical } })
    },
    isStackInCurrentOrg (stack) {
      return stack?.organization_canonical === this.orgCanonical
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:environment.component.settings',
        componentSettings: '{name} settings',
        componentInfo: {
          sectionDescription: 'Update your component name and description.',
          canonical: 'Component canonical',
          descriptionHint: 'Provide a brief description of your component.',
        },
        config: {
          title: 'Component configuration',
          description: 'The component configuration is composed of key/value pairs. Defined in the stack <code>.forms.yml</code> file, variables are filled when creating a component and can be edited at any time.',
        },
        scheduling: {
          sectionTitle: 'Scheduling',
          title: 'Schedule',
          description: 'Set up automatic start and stop for this component.',
        },
        delete: {
          description: 'Deleting this component is a permanent action that cannot be undone. Resources created through pipelines will not be deleted automatically.',
        },
      },
      es: {
        title: '@:environment.component.settings',
        componentSettings: 'Configuración de {name}',
        componentInfo: {
          sectionDescription: 'Actualice el nombre y la descripción de su componente.',
          canonical: 'Componente canónico',
          descriptionHint: 'Proporcione una breve descripción de su componente.',
        },
        config: {
          title: 'Configuración del componente',
          description: 'La configuración del componente se compone de pares clave/valor. Definidas en el archivo del stack <code>.forms.yml</code>, las variables se completan al crear un componente y se pueden editar en cualquier momento.',
        },
        scheduling: {
          sectionTitle: 'Programación',
          title: 'Programar',
          description: 'Configure el inicio y la parada automáticos para este componente.',
        },
        delete: {
          description: 'Eliminar este componente es una acción permanente que no se puede deshacer. Los recursos creados a través de los pipelines no se eliminarán automáticamente.',
        },
      },
      fr: {
        title: '@:environment.component.settings',
        componentSettings: 'Paramètres de {name}',
        componentInfo: {
          sectionDescription: 'Mettez à jour le nom et la description de votre composant.',
          canonical: 'Composant canonique',
          descriptionHint: 'Fournissez une brève description de votre composant.',
        },
        config: {
          title: 'Configuration du composant',
          description: `La configuration du composant est composée de paires clé/valeur. Définies dans le fichier de stack <code>.forms.yml</code>, les variables sont renseignées lors de la création d'un composant et peuvent être modifiées à tout moment.`,
        },
        scheduling: {
          sectionTitle: 'Planification',
          title: 'Planifier',
          description: `Configurez le démarrage et l'arrêt automatiques pour ce composant.`,
        },
        delete: {
          description: `La suppression de ce composant est une action permanente qui ne peut pas être annulée. Les ressources créées via les pipelines ne seront pas supprimées automatiquement.`,
        },
      },
    },
  },
}
</script>

<style lang="scss">
.env-component-settings {
  width: 100%;

  .card-wrapper {
    display: flex;
    gap: $spacer-4;

    .cy-avatar__icon {
      border-radius: 8px;
      font-size: 24px !important;
    }

    &__text {
      display: flex;
      gap: $spacer-4;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  .content {
    align-content: center;

    &__link {
      display: flex;
      grid-row: title / subtitle;
      place-items: center;
    }

    &__button {
      display: inline-flex;
      align-items: center;

      .v-icon {
        color: cy-get-color("primary");
      }
    }
  }

  .stack-summary__label {
    margin-top: 24px;
    margin-bottom: 8px;
    color: cy-get-color("grey", "dark-2");
  }

  .two-column-section__content .v-card,
  .danger-zone__content {
    max-width: 66%;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}
</style>
